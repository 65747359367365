import React, { useState, useEffect, useRef } from "react";
import { useHistory } from "react-router-dom";
import {
  ChatBubble,
  ChatHead,
  ChatMenuButton,
  OriginDestinationDropDown,
  EmailForm,
  SmsForm,
  UploadFile,
  NotificationBubble,
} from "../components/Components";
import firebase from "../firebase/client-app";
import { onSnapshot } from "firebase/firestore";
import { sendChat, sendSms, sendAttachment } from "../service/blackbox-service";
import sendbbx from "../assets/sound/sendbbx.mp3";
import { getListOfTopics, getListOfFiles } from "../service/blackbox-service";
import NotAllowedImage from "../assets/images/not_allowed.png";
import EmptyMailbox from "../assets/images/empty-mailbox-web-blue.png";
import OldTransactionImage from "../assets/images/old-transaction-icon.png";
import moment from "moment";

let unsubscribe = null;

const MainChatPage = () => {
  let history = useHistory();

  const db = firebase.firestore();

  const [activeChat, setActiveChat] = useState("");
  const [isLoadingScreen, setLoadingScreen] = useState(false);

  const [type, setType] = useState("");
  const [privateChat, setPrivateChat] = useState("");

  useEffect(() => {
    if (unsubscribe) {
      unsubscribe();
    }

    if (activeChat?.length !== 0) {
      unsubscribe = listener();
    }

    return () => {
      if (activeChat?.length !== 0) {
        unsubscribe();
      }
    };
  }, [activeChat]);

  const listener = () => {
    const activeGC = [...bubbleHeadList].find(
      (bbl) => bbl.header === activeChat
    );
    let chatHeadActive = activeGC?.information.chatHeads;
    let collect;
    if (chatHeadActive === "All") {
      if (history.location.state.reservationId) {
        collect = history.location.state.reservationId;
      } else {
        collect = history.location.state.topic;
      }
    } else {
      collect = history.location.state.topic;
    }

    let sfRef = db
      .collection(collect)
      .doc("conversation")
      .collection("channels")
      .doc(activeChat)
      .collection("messages")
      .orderBy("timestamp", "desc");

    return onSnapshot(sfRef, (snapShot) => {
      let tempArr = [];
      snapShot.docs.forEach((doc) => {
        tempArr.push(doc.data());
      });
      setChatList([...tempArr]);
      setTopic(history.location.state.topic);
      setReservation(history.location.state.reservationId);
      setTrip(history.location.state.tripId);
    });
  };

  const [showMenu, setShowMenu] = useState(false);

  const [bubbleHeadList, setBubbleHeadList] = useState([
    ...history.location.state.channels,
  ]);
  const cancelFormEvent = async (index) => {
    selectActiveMenuButton(index);
  };
  const sendSmsEvent = async (data) => { 
    /* SEND SMS */
    setIsLoading(true);
    selectActiveMenuButton(data?.index);
    let activeNumbers = data?.numbers;
    // try {
    //   let promiseCall = [];
    //   let payload = {
    //     topic: history.location.state.topic,
    //     channel: bubbleHeadList.find((e) => e.isActive)?.channel,
    //     type: "SMS",
    //     senderInfo: {
    //       memberId: history.location.state.memberId,
    //       name: history.location.state.companyName,
    //     },
    //     conversation: {
    //       userId: history.location.state.userId,
    //       fullName: history.location.state.fullName,
    //       userName: history.location.state.userName,
    //       to: null,
    //       message: data.message,
    //     },
    //   };
    //   await activeNumbers.forEach((e) => {
    //     payload.conversation.to = e;
    //     promiseCall.push(sendSms(payload));
    //   });
    //   Promise.allSettled(promiseCall)
    //     .then((result) => {})
    //     .catch((error) => {})
    //     .finally(() => {
    //       payload.conversation.to = activeNumbers.toString();
    //       sendChat(payload);
    //       playAudio();
    //       setIsLoading(false);
    //     });
    // } catch (err) {}
    try {
      let promiseCall = [];
      let payload = {
        topic: history.location.state.topic,
        channel: bubbleHeadList.find((e) => e.isActive)?.channel,
        type: "SMS",
        isEmergency: false,
        senderInfo: {
          memberId: history.location.state.memberId,
          name: history.location.state.companyName,
          username: history.location.state.userName,
        },
        conversation: {
          userId: history.location.state.userId,
          fullName: history.location.state.fullName,
          userName: history.location.state.userName,
        },
        smsConversation : {
          to: activeNumbers ? activeNumbers : null,
          message: data.message,
        }
      };
      // await activeNumbers.forEach((e) => {
        // payload.conversation.to = e;
      promiseCall.push(sendSms(payload));
      // });
      Promise.allSettled(promiseCall)
        .then((result) => {})
        .catch((error) => {})
        .finally(() => {
          // payload.conversation.to = activeNumbers.toString();
          // sendChat(payload);
          playAudio();
          setIsLoading(false);
        });
    } catch (err) {}
  };

  const [attachments, setAttachments] = useState([]);

  const [buttonList, setButtonList] = useState([
    {
      type: "email",
      icon: (
        <svg
          fill="currentColor"
          xmlns="http://www.w3.org/2000/svg"
          width="30"
          height="24"
          viewBox="0 0 30 24"
        >
          <path
            id="Icon_material-email"
            data-name="Icon material-email"
            d="M30,6H6A3,3,0,0,0,3.015,9L3,27a3.009,3.009,0,0,0,3,3H30a3.009,3.009,0,0,0,3-3V9A3.009,3.009,0,0,0,30,6Zm0,6L18,19.5,6,12V9l12,7.5L30,9Z"
            transform="translate(-3 -6)"
          />
        </svg>
      ),
      isActive: false,
      form: <EmailForm cancelFormEvent={cancelFormEvent} />,
    },
    {
      type: "sms",
      icon: (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="20.929"
          height="31.908"
          viewBox="0 0 20.929 31.908"
        >
          <g
            id="Group_8418"
            data-name="Group 8418"
            transform="translate(5556 13469.996)"
          >
            <path
              id="Subtraction_20"
              data-name="Subtraction 20"
              d="M17.91,31.909H3.094a3.525,3.525,0,0,1-2.189-.723A2.188,2.188,0,0,1,0,29.479V2.344C0,1.051,1.388,0,3.094,0H17.919a3.362,3.362,0,0,1,2.39.9,2.01,2.01,0,0,1,.619,1.448V29.479A2.776,2.776,0,0,1,17.91,31.909Zm-7.15-3.446A1.041,1.041,0,1,0,11.8,29.5,1.042,1.042,0,0,0,10.76,28.462ZM1.682,3.632a.169.169,0,0,0-.186.142V27.208a.169.169,0,0,0,.186.142H19.256a.17.17,0,0,0,.187-.142V3.775a.17.17,0,0,0-.187-.142ZM9.067,1.638a.339.339,0,0,0-.373.285.339.339,0,0,0,.373.284h2.8a.339.339,0,0,0,.374-.284.339.339,0,0,0-.374-.285Z"
              transform="translate(-5556 -13469.997)"
              fill="currentColor"
            />
          </g>
        </svg>
      ),
      isActive: false,
      form: (
        <SmsForm
          cancelFormEvent={cancelFormEvent}
          sendSmsEvent={sendSmsEvent}
          activeChatHead={bubbleHeadList.find((e) => e.isActive)}
          senderId={history.location.state.memberId}
        />
      ),
    },
    {
      type: "attachments",
      icon: (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="30"
          height="30"
          viewBox="0 0 30 30"
        >
          <g
            id="Icon_feather-upload"
            data-name="Icon feather-upload"
            transform="translate(-3 -3)"
          >
            <path
              id="Path_4407"
              data-name="Path 4407"
              d="M31.5,22.5v6a3,3,0,0,1-3,3H7.5a3,3,0,0,1-3-3v-6"
              fill="none"
              stroke="currentColor"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="3"
            />
            <path
              id="Path_4408"
              data-name="Path 4408"
              d="M25.5,12,18,4.5,10.5,12"
              fill="none"
              stroke="currentColor"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="3"
            />
            <path
              id="Path_4409"
              data-name="Path 4409"
              d="M18,4.5v18"
              fill="none"
              stroke="currentColor"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="3"
            />
          </g>
        </svg>
      ),
      isActive: false,
      form: <UploadFile setAttachments={setupUploadAttachment} />,
    },
  ]);

  const [showDropDown, setshowDropDown] = useState(false);

  const [currentActiveMenu, setCurrentActiveMenu] = useState(null);

  const [chatList, setChatList] = useState([]);

  const message = useRef("");

  const [topicName, setTopic] = useState("");

  const [reservationName, setReservation] = useState("");

  const [tripName, setTrip] = useState("");

  const [isLoading, setIsLoading] = useState(false);

  const [isValid, setIsValid] = useState(false);

  const isOrigin = useRef(true);
  const isPrivateActive = useRef(false);
  
  const withPrivate = (channels) => {
    const isPrivate = channels.some((data) => data.information.description.includes('PRIVATE'));
    if(isPrivate){
      //Consignee
      if (history.location.state.roleAs === "Consignee" ||
        history.location.state.roleAs === "Shipping Agency - destination") {
        const r13 = channels.find((data) => data.information.channelId === "R13");
        const {members} = r13;
        const companyArr = [];
        members.forEach((element) => {
          companyArr.push(element?.data?.guid)
        });
        return new Set(companyArr).size !== 1;
      }
      return true;
    } else {
      return false;
    }
  }

  const getAssociated = () => {
    const code = history.location.state.associate;
    const sameSC = history.location.state.isSameSC;
    if (code === '1') { //Inbound
      if (sameSC === 'true') { //Same Shipper/Consignee
        return false; //Restrict view
      }
      return true;
    }
    return true; //Outbound
  }

  useEffect(() => {
    const channels = history.location.state.channels;
    setActiveChat(channels[0]?.header);

    if (
      history.location.state.roleAs === "Shipper"
      ) {
        if (
          channels.some(
            (data) =>
              data.information.chatHeads.includes("Shipper") &&
              data.information.description.includes("ORIGIN")
          )) {
          setType("origin");
          setPrivateChat(withPrivate(channels) ? "Consignee" : '');
        } else if (
            channels.some(
              (data) =>
                data.information.chatHeads.includes("Shipper") &&
                data.information.description.includes("PRIVATE")
            )) 
        {
          setPrivateChat(withPrivate(channels) ? "Consignee" : '');
          setPrivateActive(true)
        } else {
          setType("bookingparty");
        }
    }
    
    if (
      history.location.state.roleAs === "Consignee" ||
      history.location.state.roleAs === "Shipping Agency - destination"
      ) {
        setType("destination");
        setPrivateChat(withPrivate(channels) ? "Shipper" : '');
    }

    if (!history.location.state.roleAs) {
      if (
        channels.some(
          (data) =>
            (data.information.chatHeads.includes("Shipping") ||
              data.information.chatHeads.includes("Shipper")) &&
            data.information.description.includes("ORIGIN")
        )
      ) {
        let r17 = channels.find((data) => data.information.channelId === "R17");
        let destination = channels.find((data) => data.information.description === "DESTINATION");
        if (r17) {
          setType("destination");
        } else {
          if (!destination) {
            setType("origin");
          } else {
            setType("bookingparty");
          }
        }
      } else if (
        channels.some(
          (data) =>
            data.information.chatHeads.includes("Shipping") &&
            data.information.description.includes("DESTINATION")
        )
      ) {
        setType("destination");
      } else {
        setType("trucking");
      }
    }

    let isSetOrigin = channels.filter(
      (data) =>
        data.information.description === "ORIGIN" ||
        data.information.description === "REGULAR BOOKING" ||
        data.information.description === "SUPPLEMENTARY ORIGIN" ||
        data.information.description === "PRIVATE"
    );
    if (isSetOrigin.length !== 0) {
      if (history.location.state.roleAs === "Consignee") {
        isOrigin.current = false;
      } else if (history.location.state.roleAs === "Shipper") {
        isOrigin.current = true;
      } else {
        let r17 = channels.find((data) => data.information.channelId === "R17");
        if (r17) {
          isOrigin.current = false;
        } else {
          isOrigin.current = true;
        }
      }
    } else {
      isOrigin.current = false;
    }
  }, [history.location.state]);
  
  function setOldDate() {
    const bboxLaunch = moment.utc(new Date("08/31/2022")).format("MM/DD/yyyy");
    const createdOn = moment.utc(history.location.state.createdOn).format("MM/DD/yyyy");
    return  history.location.state.createdOn && createdOn < bboxLaunch;
  }
  function setOrigin(value) {
    isOrigin.current = value;
  }
  function setPrivateActive(value) {
    isPrivateActive.current = value;
  }

  function setupUploadAttachment(event) {
    setAttachments(event);
  }

  useEffect(() => {
    if (attachments.length !== 0) {
      setIsValid(validateMultipleFile(attachments));
    } else {
      setIsValid(false);
    }
  }, [attachments]);

  function validateMultipleFile(tempFiles) {
    const isBelowThreshold = (currentSize) =>
      currentSize.size / 1024 / 1024 < 3.5;
    return tempFiles.every(isBelowThreshold);
  }

  const messageBlur = (field) => {
    if (attachments.length !== 0 || !!field) {
      setIsValid(validateMultipleFile(attachments));
    } else {
      setIsValid(false);
    }
  };

  useEffect(() => {
    if (isOrigin.current) {
      let result = bubbleHeadList.findIndex(
        (bHead) =>
          bHead.information.description === "ORIGIN" ||
          bHead.information.description === "REGULAR BOOKING" ||
          bHead.information.description === "SUPPLEMENTARY ORIGIN" ||
          bHead.information.description === "PRIVATE"
      );
      selectActiveBubbleHead(result);
    } else {
      let result = bubbleHeadList.findIndex(
        (bHead) =>
          bHead.information.description.toLowerCase().trim() ===
          "destination" ||
          bHead.information.description.toLowerCase().trim() ===
          "regular booking" ||
          bHead.information.description.toLowerCase().trim() ===
          "supplementary destination"
      );
      selectActiveBubbleHead(result);
    }
  }, [isOrigin.current]);

  function goToPreviousPage() {
    const returnListingMessage = {
      message: "listing",
    };
    window.parent.postMessage(returnListingMessage, "*");
    history.goBack();
  }

  const [selectedTitleName, setTitleName] = useState("");

  function selectActiveBubbleHead(index) {
    let currentActiveIndex = bubbleHeadList.findIndex((e) => e.isActive);
    let temp = [...bubbleHeadList];
    if (temp[currentActiveIndex]) {
      temp[currentActiveIndex].isActive = false;
    }

    let validateName = findName(temp, index, true);
    if (temp[index]) {
      temp[index].isActive = true;

      let find = temp[index].members.find(
        (res) =>
          validateRole(res.data.role).toLowerCase() ===
          (validateName.toLowerCase() === "trucking admin"
            ? "trucking"
            : validateName.toLowerCase())
      );

      if (find) {
        setTitleName(find.data.name);
      } else {
        if (isOrigin.current) {
          if (temp.find((a) => a.information.channelId === "S5")) {
            setTitleName("");
          } else {
            setTitleName("Origin");
          }
        } else {
          if (temp.find((a) => a.information.channelId === "S5")) {
            setTitleName("");
          } else {
            setTitleName("Destination");
          }
        }
      }
    }

    if (!history.location.state.roleAs) {
      temp = temp.filter(
        (fil) => fil.channel !== "R17-SUPPLEMENTARY DESTINATION"
      );
    }
    setBubbleHeadList(temp);
    setActiveChat(temp[index]?.header);
  }

  const validateRole = (data) => {
    if (data.includes("Driver")) {
      return "Trucking Driver";
    } else {
      return data;
    }
  };

  const findName = (temp, _index, chat) => {
    let tempHeader;
    if (chat) {
      if (temp[_index]) {
        tempHeader =
          temp[_index].information.chatHeads.length !== 0
            ? temp[_index].information.chatHeads.trim()
            : "N/A";
      }
    } else {
      tempHeader =
        temp?.chatHeads?.length !== 0 ? temp?.chatHeads?.trim() : "N/A";
    }
    let tempArr = tempHeader?.split(",");

    if (tempArr && tempArr.length > 1) {
      if (history.location.state.isBookingParty === "true") {
        if (
          history.location.state.roleAs === "Shipper" ||
          history.location.state.roleAs === "Consignee"
        ) {
          if (tempArr.find((ar) => ar.trim() === "Booking Party")?.trim()) {
            return tempArr.find((ar) => ar.trim() === "Booking Party").trim();
          } else {
            return tempArr
              .find((ar) => ar.trim() !== history.location.state.roleAs)
              .trim();
          }
        } else {
          return tempArr.find((ar) => ar.trim() !== "Booking Party").trim();
        }
      } else {
        if (tempArr.find((ar) => ar.trim() === "Booking Party")) {
          return tempArr.find((ar) => ar.trim() === "Booking Party").trim();
        } else if (tempArr.find((ar) => ar.trim() === "Shipping Agency")) {
          if (history.location.state.isAgency === "true") {
            return tempArr.find((ar) => ar.trim() === "Shipping Line").trim();
          } else {
            return tempArr.find((ar) => ar.trim() === "Shipping Agency").trim();
          }
        } else {
          return tempArr.find((ar) => ar.trim() === "Trucking Driver").trim();
        }
      }
    } else {
      return tempHeader?.trim();
    }
  };

  const findHeader = (bhead) => {
    let name = findName(bhead.information, "", false);
    let image;
    let validateMember = bhead.members.find(
      (res) =>
        validateRole(res.data.role).toLowerCase() ===
        (name.includes("Admin") ? "trucking" : name.toLowerCase())
    );
    if (validateMember) {
      image = validateMember.data.imageUrl;
    } else {
      image = null;
    }
    return {
      head: name,
      imageUrl: image,
    };
  };

  const selectActiveMenuButton = (index) => {
    let temp = buttonList.slice();
    let currentStatus = temp[index].isActive;

    temp.forEach((e) => (e.isActive = false));

    temp[index].isActive = !currentStatus;
    setCurrentActiveMenu(index);

    if (temp[index].type === "attachments" && !temp[index].isActive) {
      setIsValid(false);
      setAttachments([]);
    }

    if (temp[index].type !== "attachments") {
      setIsValid(false);
    }

    setButtonList(temp);
  };

  const clear = () => {
    let temp = buttonList.slice();

    temp.forEach((e) => (e.isActive = false));
    setButtonList(temp);
  };

  const topicNameView = () => {
    let topicFormat = "";
    if (tripName) {
      topicFormat = `${tripName} > ${topicName} `;
      // setTopic(topicFormat);
      return topicFormat;
    } else if (reservationName) {
      topicFormat = `${reservationName} > ${topicName} `;
      // setTopic(topicFormat);
      return topicFormat;
    } else {
      return topicName;
    }
  };

  const topicHeaderView = () => {
    if (tripName) {
      return `Service Id / Trip Id `;
    } else if (reservationName) {
      return `Reservation Id / Service Id `;
    } else {
      if (history.location.state.isBookingParty === "false") {
        return `Service Id`;
      }
      return `Reservation Id`;
    }
  };

  const gotoMembersPage = () => {
    const activeGC = [...bubbleHeadList].find(
      (bbl) => bbl.header === activeChat
    );
    const members = activeGC.members;
    if (activeGC) {
      history.push({
        pathname: `/chat/${activeChat}/members`,
        state: {
          members: [
            {
              ...members.find(
                (member) => member.data.guid === history.location.state.memberId
              ),
            },
            ...members.filter(
              (member) => member.data.guid !== history.location.state.memberId
            ),
          ],
          currentUser: history.location.state.memberId,
          topic: topicNameView(),
          title: selectedTitleName,
          topicHeader: topicHeaderView(),
          activeChat,
        },
      });
    }
  };
  const gotoFilesPage = async () => {
    const activeGC = [...bubbleHeadList].find(
      (bbl) => bbl.header === activeChat
    );
    
    let chatHeadActive = activeGC.information.chatHeads;
    let payload = {
      channel: activeChat,
      topic: (chatHeadActive !== "All") ? topicName : reservationName ? reservationName : topicName,
      "IsFileListing": true
    };
    const result = await getListOfFiles(payload);
    let fileList = [];
    if (result.status === 200) {
      fileList = result.data.data;
    }
    const members = activeGC.members;
    if (activeGC) {
      history.push({
        pathname: `/chat/${activeChat}/files`,
        state: {
          members: [
            {
              ...members.find(
                (member) => member.data.guid === history.location.state.memberId
              ),
            },
            ...members.filter(
              (member) => member.data.guid !== history.location.state.memberId
            ),
          ],
          files: fileList,
          currentUser: history.location.state.memberId,
          topic: topicNameView(),
          title: selectedTitleName,
          topicHeader: topicHeaderView(),
          activeChat,
        },
      });
    }
  };

  const toBase64 = (file) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });

  const gotoTripPage = async () => {
    setLoadingScreen(true);
    let payload = {
      memberId: history.location.state.memberId,
      topic: topicName,
      filters: [],
    };
    const result = await getListOfTopics(payload);
    if (result.status === 200) {
      setLoadingScreen(false);
      let {
        data: {
          data: { topics },
        },
      } = result;

      history.push({
        pathname: `/chat/${activeChat}/trips`,
        state: {
          trips: topics[0].information,
          topic: topicNameView(),
          title: selectedTitleName,
          topicHeader: topicHeaderView(),
          activeChat,
        },
      });
    } else {
      setLoadingScreen(false);
    }
  };

  function validateSend(mess, file) {
    if (!file) {
      return false;
    } else {
      if (mess) {
        let validate = validateMultipleFile(file);
        return validate ? false : true;
      } else {
        let validate = validateMultipleFile(file);
        return validate ? false : true;
      }
    }
  }

  const sendMessage = async () => {
    const trimmedMessage = message.current.value;

    if (
      validateSend(trimmedMessage.toString().trim().length === 0, attachments)
    ) {
      return;
    }

    let finalAttachments = [];

    let type = "CHAT";

    let result;
    setIsLoading(true);
    if (attachments.length !== 0) {
      type = "ATTACHMENT"
    }

    const activeGC = [...bubbleHeadList].find(
      (bbl) => bbl.header === activeChat
    );

    let chatHeadActive = activeGC.information.chatHeads;

    let collectHead;
    if (chatHeadActive === "All") {
      if (history.location.state.tripId) {
        collectHead = history.location.state.topic;
        submitAll(collectHead, trimmedMessage);
      } else {
        collectHead = history.location.state.reservationId;
        submitAll(collectHead, trimmedMessage);
      }
    } else {
      collectHead = history.location.state.topic;
      let payload = payloadData(
        finalAttachments,
        type,
        trimmedMessage,
        collectHead
      );
      let result;
      if (type === "ATTACHMENT") {
        if (attachments.length === 0) {
          if (trimmedMessage) {
            payload = payloadData([], "CHAT", trimmedMessage, collectHead);
            result = await sendChat(payload);
          }
          resultdata(result, finalAttachments)
        } else {
          if (trimmedMessage) {
            payload = payloadData([], "CHAT", trimmedMessage, collectHead);
            result = await sendChat(payload);
          }
          for await (const element of attachments) {
            let tempAttachments = await getImage(element);
            finalAttachments = tempAttachments;
            payload = payloadData(finalAttachments, type, "", collectHead);
            result = await sendAttachment(payload);
          }
        }
      } else {
        result = await sendChat(payload);
      }

      resultdata(result, finalAttachments);
    }
  };

  const resultdata = (result, finalAttachments) => {
    if (result.status === 200) {
      setIsLoading(false);
      playAudio();
      scrollToBottom();
      if (finalAttachments.length) {
        clear();
        setAttachments([]);
      }
      // setMessage("");
      message.current.value = "";
      messageBlur(message.current.value);
    } else {
      setIsLoading(false);
      messageBlur(message.current.value);
    }
  };


  const submitAll = async (collectHead, trimmedMessage) => {
    let type;
    let finalAttachments = [];
    let result;
    if (attachments.length === 0) {
      if (trimmedMessage) {
        let payload = payloadData([], "CHAT", trimmedMessage, collectHead);
        result = await sendChat(payload);
      }

      resultdata(result, finalAttachments)
    } else {
      if (trimmedMessage) {
        let payload = payloadData([], "CHAT", trimmedMessage, collectHead);
        await sendChat(payload);
      }
      for await (const element of attachments) {
        let tempAttachments = await getImage(element)
        type = "ATTACHMENT";
        finalAttachments = tempAttachments;
        submitPayload(finalAttachments, type, "", collectHead)
      }
    }
  };

  const submitPayload = async (
    finalAttachments,
    type,
    trimmedMessage,
    collectHead?
  ) => {
    let Allpayload = payloadData(
      finalAttachments,
      type,
      trimmedMessage,
      collectHead
    );
    let result;
    if (type === "ATTACHMENT") {
      result = await sendAttachment(Allpayload);
    } else {
      result = await sendChat(Allpayload);
    }
    resultdata(result, finalAttachments)
  }



  const payloadData = (
    finalAttachments,
    type,
    trimmedMessage,
    collectHead?
  ) => {
    return {
      topic: collectHead ? collectHead : history.location.state.topic,
      channel: activeChat,
      senderInfo: {
        memberId: history.location.state.memberId,
        name: history.location.state.companyName,
        userName: history.location.state.fullName,
      },
      conversation: {
        userId: history.location.state.userId,
        message: trimmedMessage,
        fullName: history.location.state.fullName,
        userName: history.location.state.userName,
        attachments: finalAttachments,
      },
      type,
    };
  };
  const getImage = async (files) => {
    let tempArr = [];
    const base = await toBase64(files);

    let fileType = base
      .split(",")[0]
      .replace("data:", "")
      .replace("base64", "")
      .replace(";", "");

    let file = files.name.split(".");

    tempArr.push({
      data: base.split(",")[1],
      fileName: files.name,
      fileExtension: file[1],
      fileType,
    });

    return tempArr

  }

  const playAudio = () => {
    document.getElementById("myAudio").play();
  };

  const scrollToBottom = () => {
    const bubblehouse = document.getElementById("bubblehouse");
    bubblehouse.scrollTop = bubblehouse.scrollHeight;
  };

  const viewCardHeader = (description, bHead, index) => {
    if (isOrigin.current) {
      if (
        description === "ORIGIN" ||
        description === "REGULAR BOOKING" ||
        description === "SUPPLEMENTARY ORIGIN" ||
        description === "PRIVATE"
      ) {
        return chatHead(bHead, index);
      }
    } else {
      if (
        description === "DESTINATION" ||
        description === "REGULAR BOOKING" ||
        description === "SUPPLEMENTARY DESTINATION"
      ) {
        return chatHead(bHead, index);
      }
    }
  };

  const chatHead = (bHead, index) => {
    return (
      <ChatHead
        key={index}
        bHead={bHead}
        header={findHeader(bHead)}
        notification={bHead.notification}
        isActive={bHead.isActive}
        index={index}
        onClick={selectActiveBubbleHead}
        isBookingParty={history.location.state.isBookingParty}
        token={history.location.state.token}
      />
    );
  };

  const goToSearchConversation = () => {
    history.push({
      pathname: `/chat/${activeChat}/search`,
      state: {
        currentUser: history.location.state.memberId,
        topic: topicName,
        title: selectedTitleName,
        chatList,
        activeChat,
        userId: history.location.state.userId,
      },
    });
  };

  return bubbleHeadList.length !== 0 && !isLoadingScreen ? (
    <div className="min-h-screen min-w-full flex flex-col pt-8">
      {getAssociated() ? (          
        <>
          <div className="flex flex-row justify-between px-2">
            <div className="flex flex-col items-center justify-center">
              <svg
                onClick={() => goToPreviousPage()}
                xmlns="http://www.w3.org/2000/svg"
                className="h-10 w-10 text-secondary cursor-pointer"
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                <path
                  fillRule="evenodd"
                  d="M12.707 5.293a1 1 0 010 1.414L9.414 10l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z"
                  clipRule="evenodd"
                />
              </svg>
            </div>
            <div className="flex flex-col text-center">
              <p className="text-md">
                {topicHeaderView()}{" "}
                {bubbleHeadList.find((bHead) => bHead.isActive)?.serviceHeader}
              </p>
              <p className="text-xl font-semibold text-secondary ">
                {topicNameView()}{" "}
                {bubbleHeadList.find((bHead) => bHead.isActive)?.chatHeader}
              </p>
              <p className="text-xl font-semibold">{selectedTitleName}</p>
            </div>
            <div className="flex flex-col items-center justify-center relative">
              <svg
                onClick={() => setshowDropDown((showDropDown) => !showDropDown)}
                xmlns="http://www.w3.org/2000/svg"
                className="h-9 w-9 text-secondary cursor-pointer"
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                <path d="M10 6a2 2 0 110-4 2 2 0 010 4zM10 12a2 2 0 110-4 2 2 0 010 4zM10 18a2 2 0 110-4 2 2 0 010 4z" />
              </svg>
              {showDropDown && (
                <OriginDestinationDropDown
                  type={type}
                  privateChat={privateChat}
                  isPrivateActive={isPrivateActive.current}
                  setPrivateActive={setPrivateActive}
                  tripName={tripName}
                  setOrigin={setOrigin}
                  isOrigin={isOrigin.current}
                  isShowDrop={setshowDropDown}
                  goToMembers={gotoMembersPage}
                  goToFiles={gotoFilesPage}
                  gotoTripPage={gotoTripPage}
                  goToSearchConversation={goToSearchConversation}
                />
              )}
            </div>
          </div>
          <div className="flex flex-row h-36 justify-start w-full bg-gray-100 py-4 gap-2 overflow-x-scroll my-2">
            {bubbleHeadList.map((bHead, index) => {
              return viewCardHeader(
                bHead.information.description,
                bHead,
                index
              );
            })}
          </div>
          <div className="flex-grow h-full w-full overflow-hidden relative">
            <audio id="myAudio">
              <source src={sendbbx} type="audio/mp3" />
            </audio>
            <div
              id="bubblehouse"
              className="absolute top-0 left-0 right-0 bottom-0 overflow-y-auto flex flex-col-reverse gap-4 pb-2 "
            >
              {chatList.length !== 0 &&
                chatList.map((chat) =>
                  chat?.type?.toLowerCase() !== "notification" ? (
                    <ChatBubble
                      isSelf={
                        chat.conversation.userId ===
                        history.location.state.userId
                      }
                      {...chat}
                      key={chat.timestamp}
                    />
                  ) : (
                    <NotificationBubble
                      topicName={topicNameView()}
                      hasAttachment={false}
                      {...chat}
                      key={chat.timestamp}
                    />
                  )
                )}
              {chatList.length === 0 && (
                <div className="flex flex-col items-center">
                  <img
                    src={EmptyMailbox}
                    alt="Empty Mailbox"
                    className="h-52"
                  />
                  <p className="text-xl font-bold text-gray-600 mt-2">Oops!</p>
                  <p className="text-md text-gray-500">
                    There are no new messages.
                  </p>
                </div>
              )}
            </div>
          </div>
          <div className="flex flex-col">
            {buttonList &&
              buttonList.some((e) => e.isActive) &&
              buttonList[currentActiveMenu].form}
            <div
              className={`${showMenu ? "max-h-40" : "max-h-0 "
                } bg-gray-100 overflow-hidden transition-all duration-400 ease-in-out relative w-full`}
            >
              <div className="px-4 py-2 border-t w-full flex flex-row gap-4">
                {buttonList &&
                  buttonList.map((btn, index) => (
                    <ChatMenuButton
                      key={index}
                      icon={btn.icon}
                      index={index}
                      isActive={btn.isActive}
                      onClick={selectActiveMenuButton}
                    />
                  ))}
              </div>
            </div>
            <div className="flex flex-row p-4 gap-4 align-middle items-center shadow-sm">
              <svg
                onClick={() => {
                  if (showMenu) {
                    clear();
                    setIsValid(false);
                    setAttachments([]);
                  }
                  setShowMenu((showMenu) => !showMenu);
                }}
                xmlns="http://www.w3.org/2000/svg"
                className="h-9 w-9 text-secondary cursor-pointer"
                viewBox="0 0 20 20"
                fill={!showMenu ? "currentColor" : "#0ea5e9"}
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d={
                    showMenu
                      ? "M10 18a8 8 0 100-16 8 8 0 000 16zM8.707 7.293a1 1 0 00-1.414 1.414L8.586 10l-1.293 1.293a1 1 0 101.414 1.414L10 11.414l1.293 1.293a1 1 0 001.414-1.414L11.414 10l1.293-1.293a1 1 0 00-1.414-1.414L10 8.586 8.707 7.293z"
                      : "M10 18a8 8 0 100-16 8 8 0 000 16zm1-11a1 1 0 10-2 0v2H7a1 1 0 100 2h2v2a1 1 0 102 0v-2h2a1 1 0 100-2h-2V7z"
                  }
                />
              </svg>
              <textarea
                className="bg-gray-100 rounded-md w-full py-2 pr-4 pl-2 text-gray-700 leading-tight focus:outline-none resize-none"
                type="text"
                placeholder="Type here..."
                // onChange={(e) => setMessage(e.target.value)}
                ref={message}
                onChange={() => messageBlur(message.current.value)}
                disabled={isLoading}
                minLength="1"
                maxLength="2600"
              />
              {isLoading ? (
                <svg
                  className="animate-spin -ml-1 mr-3 h-6 w-6 text-secondary"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                >
                  <circle
                    className="opacity-25"
                    cx="12"
                    cy="12"
                    r="10"
                    stroke="currentColor"
                    strokeWidth="4"
                  ></circle>
                  <path
                    className="opacity-75"
                    fill="currentColor"
                    d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                  ></path>
                </svg>
              ) : (
                <svg
                  className="cursor-pointer"
                  xmlns="http://www.w3.org/2000/svg"
                  width="33.939"
                  height="28.386"
                  viewBox="0 0 33.939 28.386"
                  onClick={() => sendMessage()}
                >
                  <path
                    id="Icon_material-send"
                    data-name="Icon material-send"
                    d="M3.016,32.886,36.939,18.693,3.016,4.5,3,15.539l24.242,3.154L3,21.847Z"
                    transform="translate(-3 -4.5)"
                    fill={isValid ? "#04527f" : "#F3F4F6"}
                  />
                </svg>
              )}
            </div>
          </div>
        </>
        ) : (
          <div className="min-h-screen min-w-full flex flex-col pt-8">
            <div className="flex flex-row justify-between px-2">
              <div className="flex flex-col items-center justify-center">
                <svg
                  onClick={() => goToPreviousPage()}
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-10 w-10 text-secondary cursor-pointer"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                >
                  <path
                    fillRule="evenodd"
                    d="M12.707 5.293a1 1 0 010 1.414L9.414 10l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z"
                    clipRule="evenodd"
                  />
                </svg>
              </div>
              <div className="flex flex-col text-center">
                <p className="text-md">
                  {topicHeaderView()}{" "}
                  {/* {bubbleHeadList.find((bHead) => bHead.isActive)?.serviceHeader} */}
                </p>
                <p className="text-xl font-semibold text-secondary ">
                  {topicNameView()}{" "}
                  {/* {bubbleHeadList.find((bHead) => bHead.isActive)?.chatHeader} */}
                </p>
                <p className="text-xl font-semibold">{selectedTitleName}</p>
              </div>
              <div className="flex flex-col items-center justify-center relative"></div>
            </div>
            <div className="flex-1 flex flex-col w-full h-full items-center justify-center">
              <div className="flex flex-row items-center justify-center">
                <div className="flex flex-col items-center">
                  <img src={NotAllowedImage} alt="Not Allowed" />
                  <p className="text-4xl font-bold text-gray-600 mt-2">Oops!</p>
                  <p className="text-xl text-gray-500">
                    You are not allowed to view this conversation.
                  </p>
                </div>
              </div>
            </div>
          </div>
          
          // <div className="flex flex-col justify-center items-center h-160">
          //   <svg
          //     role="status"
          //     className="inline w-22 h-16 mr-2 text-gray-200 animate-spin dark:text-gray-600 "
          //     viewBox="0 0 100 101"
          //     fill="none"
          //     xmlns="http://www.w3.org/2000/svg"
          //   >
          //     <path
          //       d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
          //       fill="currentColor"
          //     />
          //     <path
          //       d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
          //       fill="#243c5a"
          //     />
          //   </svg>
          // </div>
        )
      }
    </div>
  ) : (
    setOldDate() ?

    (<div className="min-h-screen min-w-full flex flex-col pt-8">
      <div className="flex flex-row justify-between px-2">
        <div className="flex flex-col items-center justify-center">
          <svg
            onClick={() => goToPreviousPage()}
            xmlns="http://www.w3.org/2000/svg"
            className="h-10 w-10 text-secondary cursor-pointer"
            viewBox="0 0 20 20"
            fill="currentColor"
          >
            <path
              fillRule="evenodd"
              d="M12.707 5.293a1 1 0 010 1.414L9.414 10l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z"
              clipRule="evenodd"
            />
          </svg>
        </div>
        <div className="flex flex-col text-center">
          <p className="text-md">
            {topicHeaderView()}{" "}
            {/* {bubbleHeadList.find((bHead) => bHead.isActive)?.serviceHeader} */}
          </p>
          <p className="text-xl font-semibold text-secondary ">
            {topicNameView()}{" "}
            {/* {bubbleHeadList.find((bHead) => bHead.isActive)?.chatHeader} */}
          </p>
          <p className="text-xl font-semibold">{selectedTitleName}</p>
        </div>
        <div className="flex flex-col items-center justify-center relative"></div>
      </div>
      <div className="flex-1 flex flex-col w-full h-full items-center justify-center">
        <div className="flex flex-row items-center justify-center">
          <div className="flex flex-col items-center text-center">
            <img src={OldTransactionImage} alt="Old Transaction" />
            <p className="text-4xl font-bold text-gray-600 mt-2">Sorry!</p>
            <p className="text-xl text-gray-500">
              This feature is not yet available for transactions created prior to the launch of the Blackbox.
            </p>
          </div>
        </div>
      </div>
    </div>)

    :

    (
      isLoadingScreen ? 
      (
        <div className="flex flex-col justify-center items-center h-160">
            <svg
              role="status"
              className="inline w-22 h-16 mr-2 text-gray-200 animate-spin dark:text-gray-600 "
              viewBox="0 0 100 101"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                fill="currentColor"
              />
              <path
                d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                fill="#243c5a"
              />
            </svg>
          </div>
      )
      :
      (
        <div className="min-h-screen min-w-full flex flex-col pt-8">
          <div className="flex flex-row justify-between px-2">
            <div className="flex flex-col items-center justify-center">
              <svg
                onClick={() => goToPreviousPage()}
                xmlns="http://www.w3.org/2000/svg"
                className="h-10 w-10 text-secondary cursor-pointer"
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                <path
                  fillRule="evenodd"
                  d="M12.707 5.293a1 1 0 010 1.414L9.414 10l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z"
                  clipRule="evenodd"
                />
              </svg>
            </div>
            <div className="flex flex-col text-center">
              <p className="text-md">
                {topicHeaderView()}{" "}
                {/* {bubbleHeadList.find((bHead) => bHead.isActive)?.serviceHeader} */}
              </p>
              <p className="text-xl font-semibold text-secondary ">
                {topicNameView()}{" "}
                {/* {bubbleHeadList.find((bHead) => bHead.isActive)?.chatHeader} */}
              </p>
              <p className="text-xl font-semibold">{selectedTitleName}</p>
            </div>
            <div className="flex flex-col items-center justify-center relative"></div>
          </div>
          <div className="flex-1 flex flex-col w-full h-full items-center justify-center">
            <div className="flex flex-row items-center justify-center">
              <div className="flex flex-col items-center">
                <img src={NotAllowedImage} alt="Not Allowed" />
                <p className="text-4xl font-bold text-gray-600 mt-2">Oops!</p>
                <p className="text-xl text-gray-500">
                  You are not allowed to view this conversation.
                </p>
              </div>
            </div>
          </div>
        </div>
      )
    )
  );
};

export default MainChatPage;
